import {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router";
import ReactSelect from "react-select";
import {Button, get_duration_info, Loader, Modal} from "./Components";
import {DateTime, Duration} from "luxon";
import {Add, Delete} from "@mui/icons-material";
import {Link} from "react-router-dom";

/**
 * Represents a grade category (line item in grading criteria in syllabus)
 * @param {weight} Integer 0>x>=100 representing the weightage of the particular category
 * @param {name} Grade category name (e.g. "homework")
 * @param {earned} Percent of this category the student has earned (for eaxmple, 50% if the average homework grade is 50% and this category is homeworks)
 */
function SummaryCategory({name, weight, earned, ...props}) {
	return (
		<div className="grades__summary__category"> 
			<div className="grades__summary__category__header">
				{/*contains name and weight */}
				{name} (worth {weight}%)
			</div>
			<div className="grades__summary__category__earned">
				{earned}%
			</div>
			{/*<div className="grades__summary__category__action">
				Show only {name} grades
				TODO: filters grades by this category 
			</div>*/}
		</div>
	)
}

function GradeSummary({categories, grades, ...props}) {
	let computed = {overall:null, by_category:{}}

	if(grades && categories) {
		computed = compute_overall_grade(grades, categories)
	}

	return (
		<div className="grades__summary">
			<div className="grades__summary__overall">
				{computed.overall==null ? <Loader light/> : (isNaN(computed.overall) ? "~" : Math.floor(computed.overall))}<small className="grades__summary__overall__icon">%</small>
			</div>
			<div className="grades__summary__categories">
				{categories && Object.keys(categories).map((category_id) => <SummaryCategory key={category_id} name={categories[category_id].name} earned={computed.by_category[category_id]==null ? "--": Math.floor(100*computed.by_category[category_id].points_earned_hundreths/computed.by_category[category_id].points_total_hundreths)} drops={categories[category_id].drops} weight={categories[category_id].weight} />)}
			</div>
			<div className="grades__summary__actions">
				{/*<Button>Syllabus</Button>
				<Button secondary>Gradebook Settings</Button> {/* waive passCS guarantee, turn notification on/off, upload new syllabus, change grade criteria*/}
			</div>
		</div>
	)
}

function NewGradeForm({gradebook_id, categories, grades, setGrades, ...props}) {
	const [name, setName] = useState("")
	const [pointsEarned, setPointsEarned] = useState("")
	const [pointsTotal, setPointsTotal] = useState("")
	const [dueDate, setDueDate] = useState("")
	const [selectedCategory, setSelectedCategory] = useState(null)

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	let submit = async () => {
		setError(null);
		
		if(grades === null) {
			setError("Please wait, loading existing grades");
			return;
		}
		// validation
		if(name == null || name === "") {
			setError("Please enter an assignment name")
			return;
		}

		if(isNaN(parseInt(pointsTotal))) {
			setError("The total number of points must be a number")
			return;
		}

		if(parseInt(pointsTotal) <= 0) {
			setError("The assignment must have a positive number of total points");
			return;
		}
		
		if(isNaN(parseInt(pointsEarned))) {
			setError("The earned number of points must be a number")
			return;
		}

		if(parseInt(pointsEarned) < 0) {
			setError("The assignment must have a non-negative number of earned points");
			return;
		}

		if(selectedCategory == null) {
			setError("Please select an assignment category for this grade");
			return;
		}

		if(dueDate === "" || dueDate == null) {
			setError("Please select a due date for this assignment");
			return;
		}

		


		// even if we only support taking assingments that are graded, we will still allow future due dates 
		// it's possible that someone submitted their assignment early (and it got graded early)

		try {
			setLoading(true)
			let grades_form = new FormData();
			grades_form.append("name", name)
			grades_form.append("due_date", dueDate[Symbol.toPrimitive]("number")/1000)
			grades_form.append("points_recieved_hundreths", Math.floor(pointsEarned*100))
			grades_form.append("points_total_hundreths", Math.floor(pointsTotal*100))

			let graderesp = await fetch(`/api/gradebooks/${gradebook_id}/categories/${selectedCategory.value}/grades`, {method: "POST", body: grades_form})
			let gradedata = await graderesp.json();

			if(gradedata.status === "success") {
				setGrades([...grades, gradedata.data])
			} else if(gradedata.status === "failure") {
				setError({
					"GradeAlreadyExists":"A grade with this name already exists, please try a different assignment name",
					"DBError":"Internal Server Error",
					"Unauthorized":"Unauthorized",
					"CategoryNotFound":"The selected grade category no longer exists. Please refresh and try agian",
					"GradebookNotFound":"This gradebook no longer exists. Please refresh and try agian"
				}[gradedata.error] || gradedata.error);
			}

			setLoading(false)
		} catch(e) {
			console.log(e);
			setError("Couldn't contact server. Try again later.");
			setLoading(false);
		}
	}

	let categoryOptions = categories == null ? null : Object.keys(categories).map((category_id) => {return {label: categories[category_id].name, value: category_id}})

	return (
		<form onSubmit={(e) => { e.preventDefault(); submit() }} className="grades__newgrade">
			<div className="generic_form__inputs">
				<div className="generic_form__inputgroup">
					<label className="generic_form__label" htmlFor="assn-name">Assignment Name</label>
					<input onChange={(e) => setName(e.target.value)} id="assn-name" placeholder="Homework #2" type="text"/>
				</div>
				
				<div className="generic_form__inputgroup">
					<label className="generic_form__label" htmlFor="points-earned">Points Earned</label>
					<input onChange={(e) => setPointsEarned(e.target.value)} id="points-earned" placeholder="9" type="number" />
				</div>

				<div className="generic_form__inputgroup">
					<label className="generic_form__label" htmlFor="points-total">Points Total</label>
					<input onChange={(e) => setPointsTotal(e.target.value)} id="points-total" placeholder="10" type="number" />
				</div>

				<div className="generic_form__inputgroup">
					<label className="generic_form__label" htmlFor="due-date">Due Date</label>
					<input id="due-date" type="date" value={DateTime.fromJSDate(dueDate).toFormat("yyyy-MM-dd")} onChange={(e) => {
					let parsed_time = DateTime.fromISO(e.target.value);
					if(!parsed_time.invalid) {
						console.log(DateTime.fromJSDate(parsed_time.toJSDate()).toFormat("yyyy-MM-dd"))
						setDueDate(parsed_time.toJSDate())
					}
				}}/>
				</div>


				<div className="generic_form__inputgroup">
					<label className="generic_form__label" htmlFor="points-total">Category</label>
					<ReactSelect className="generic_form__reactselect" value={selectedCategory} onChange={(val) => setSelectedCategory(val)} placeholder="Select or type..." options={categoryOptions} />
				</div>
			</div>
			{error && <span className="genericError">{error}</span>}

			<Button loading={loading} onClick={submit} thin>Add to Gradebook</Button>
		</form>
	)
}

export function Grade({name, category, score, points_earned, points_total, due_date, entered_date, in_summary, className, deleteGrade, ...props}) {
	const [friendlyDueDate, setFriendlyDueDate] = useState("forver");
	const [friendlyEnteredDate, setFriendlyEnteredDate] = useState("forever");
	const [deletePending, setDeletePending] = useState(false);

	const deleteThisGrade = async () => {
		setDeletePending(true)
		await deleteGrade();
		setDeletePending(false)
	}

	useEffect(() => {
		setFriendlyDueDate(get_duration_info(new Date(due_date*1000)))
		setFriendlyEnteredDate(get_duration_info(new Date(entered_date*1000)))

		let timeUpdator = setInterval(() => {
			setFriendlyDueDate(get_duration_info(new Date(due_date*1000)))
			setFriendlyEnteredDate(get_duration_info(new Date(entered_date*1000)))
		}, 1000);
		
		return () => {
			clearInterval(timeUpdator);
		}

	},[due_date, entered_date])


	return (
		<div className={"grades__grade "+(className || "")}>
			<div className="grades__grade__header">
				<span className="grades__grade__header__name">{name}</span> - {category}
			</div>
			<div className="grades__grade__performance">
				{score}% <span className="grades__grade__performance__detail">({points_earned}/{points_total})</span>
			</div>
			<div className="grades__grade__footer">
				<div className="grades__grade__dates">
					Due {friendlyDueDate}. <br/>Entered {friendlyEnteredDate}.
				</div>
				<div role="button" onClick={deleteThisGrade} className="grades__grade__delete">
					{deletePending ? <Loader /> : <Delete />}
				</div>
			</div>
		</div>
	);
}

function Grades({grades, categories, deleteGrade, ...props}) {
	return (
		<div className="grades__grades">
			{grades && grades.sort((a,b) => {return b.grade_entered_date - a.grade_entered_date}).map((grade) => <Grade key={grade.id} deleteGrade={() => deleteGrade(grade)} name={grade.name} category={categories[grade.grade_category].name} score={Math.floor(grade.points_recieved_hundreths*100/grade.points_total_hundreths)} points_earned={grade.points_recieved_hundreths/100} points_total={grade.points_total_hundreths/100} due_date={grade.due_date} entered_date={grade.grade_entered_date}/>)}
		</div>
	);
}

/**
 * Element the user sees that represents a category in the setup dialog
 * name - The name of the category
 * weight - How much the grade category is worth
 * drops - How many of the lowest assignments are dropped
 */
function CategorySetupCategory({name, weight, drops, deleteCategory, ...props}) {
	return (<>
		<div className="grades__setup__form__category">
			<div className="grades__setup__form__category__name">
				{name}
			</div>
			<div className="grades__setup__form__category__percent">
				<span>worth <b>{weight}%</b></span>
			</div>
			<div className="grades__setup__form__category__drops">
				{drops} lowest scores dropped 
			</div>
			<div className="grades__setup__form__category__buttoncontainer">
				<button onClick={deleteCategory} className="iconbutton">
					<Delete />
				</button>
			</div>
		</div>
		</>);
}

/**
 * Component that lets a user populate a homework category to be staged into the form
 */
function CategorySetupNewCategoryForm({addCategory, gradebook_id, ...props}) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [weightage, setWeightage] = useState("");
	const [drops, setDrops] = useState(0);
	const [error, setError] = useState(null);

	let submit = async () => {
		setLoading(true);
		let parsed_weightage = parseInt(weightage);
		setError(null)
		if(name == null || name.length===0) {
			setLoading(false);
			setError("The category must have a name");
			return;
		}

		if(weightage=="" || isNaN(weightage) || parsed_weightage < 0 || parsed_weightage > 100) {
			setLoading(false);
			setError("The grade category's weightage must be between 0 and 100");
			return;
		}

		if(isNaN(drops) || drops < 0) {
			setLoading(false);
			setError("You must have a non-negative number of dropped assignments. If your syllabus does not \"drop\" any lowest grades, enter 0");
			return;
		}

		let form_data = new FormData();
		form_data.append("weight_percent", weightage);
		form_data.append("drops", drops);
		form_data.append("name", name);

		try {
			let categoriesresp = await fetch(`/api/gradebooks/${gradebook_id}/categories`, {method:"POST", body: form_data});
			let categoriesdata = await categoriesresp.json();
			console.log(categoriesdata);
			addCategory(categoriesdata.data.category_name, categoriesdata.data.weight_percent, categoriesdata.data.drops, categoriesdata.data.id);

			setLoading(false)
			setName("");
			setWeightage("");
			setDrops(0);

		} catch(e) {
			setLoading(false);
			setError(e.type)
		}

	}

	return (<>
			<div className="grades__setup__form__newcategory">
				<form onSubmit={(e) => {e.preventDefault(); submit()}} className="generic_form__inputs">
					<div className="generic_form__inputgroup">
						<label className="generic_form__label" htmlFor="category-name">Grade Category</label>
						<input value={name} onChange={(e) => setName(e.target.value)} id="category-name" placeholder="Midterm" type="text"/>
					</div>
					
					<div className="generic_form__inputgroup">
						<label className="generic_form__label" htmlFor="weightage">Weight</label>
						<div><input value={weightage} onChange={(e) => setWeightage(e.target.value)} className="generic_form__input--short" id="weightage" placeholder="25" type="number" /> <div className="grades__setup__form__newcategory__icon">%</div></div>
					</div>

					<div className="generic_form__inputgroup">
						<label className="generic_form__label" htmlFor="drops">Dropped Assignments</label>
						<input id="drops" value={drops} onChange={(e) => setDrops(e.target.value)} className="generic_form__input--short" type="number" placeholder="0"/>
					</div>
					<button type="submit" className="grades__setup__form__newcategory__submit--iconbutton iconbutton iconbutton--primary"><Add /></button>
					<Button loading={loading} onClick={submit} extraClasses="grades__setup__form__newcategory__submit--button">Add Category</Button>
			</form>
				{error && 
				<>
					<br/>
					<div className="genericError">
						{error}
					</div>
				</>
				}
		</div>
		</>)
}

function has_bad_category_sum(categories) {
		const weight_sum = Object.keys(categories).reduce((sum,category_id) => {return sum + parseInt(categories[category_id].weight)}, 0)
		console.log(weight_sum);
		return weight_sum < 98 || weight_sum > 101
}

// TODO: Make this more flexible so it can be used to edit already-submitted categories. Right now, once done is hit (and data hits the server), removing a category doesn't actually do anything (remember, it only removes staged categories).
/**
 * Customer (or tutor in first session) sets up a gradebook (and its grade categories) using the syllabus
 * gradebook_id - id of the gradebook we're adding categories to
 * onComplete - function to call when category setup is complete
 */
export function CategorySetupView({tutorview, gradebook_id, gradebook, onComplete, categories, setCategories, ...props}) { 
	//TODO: some way to reasonably find the gradebook id if it doesn't already exist. Maybe a create-if-not-exists endpoint that returns the gradebook
	let [error, setError] = useState(null)

	let removeCategory = async (category_id) => {
		//TODO: if we let people edit categories after they've been created, we need to handle the case where a category cannot be deleted because it has attached grades
		try {
			let delcatresp = await fetch(`/api/gradebooks/${gradebook_id}/categories/${category_id}`, {method:"DELETE"});
			let delcatdata = await delcatresp.json();

			if(delcatdata.status !== "success") {
				throw delcatdata;
			}
			delete categories[delcatdata.data.id];
			setCategories({...categories});
		} catch(e) {
			setError(e.type);
			console.log(e);
		}
	}

	let submit = async () => {
		setError(null);

		// validation
		if(categories.length === 0) {
			setError("Use the form to input at least one category");
			return;
		}

		// check thta the weightage is at least 98%. We can stuff the extra 1-2% somewhere else. We only accept integer percents, and if the class has 33 + 33 + 33, there will be an extra 1%
		if(has_bad_category_sum(categories)) {
			setError("The weights of each category must add up to 100%. Are you sure you entered all the categories from your syllabus?");
			return;
		}

		onComplete();
	}

	return (<>
		<section>
			<h2 className="dash__content__title">
				Setup your gradebook
			</h2>
			<div className="grades__setup">
				<h3 className="grades__setup__title">Grading Criteria for {gradebook?.course?.course_number && gradebook.course.course_number}</h3>
				<p className="grades__setup__tagline">Enter the grade categories as they appear on your {gradebook?.course?.course_number && gradebook.course.course_number} syllabus</p>
				<p className="grades__setup__hesitation">This step is designed to take fewer than 3 minutes of your time</p>
				<div className="grades__setup__form">
					<CategorySetupNewCategoryForm gradebook_id={gradebook_id} addCategory={(name, weight,drops, id) => setCategories({...categories, [id]: {name, weight, drops}})} />
					{Object.keys(categories).length === 0 && <div className="grades__setup__form__category grades__setup__form__category--empty">
						Use the form above to input the grading criteria from your syllabus
					</div>}
					{Object.keys(categories).length !== 0 && Object.keys(categories).map((category_id) => <CategorySetupCategory key={category_id} name={categories[category_id].name} weight={categories[category_id].weight} drops={categories[category_id].drops} deleteCategory={() => removeCategory(category_id)}/>)}

				</div>
				<div className="grades__setup__footer">
					<div className="grades__setup__footer__error genericError">{error}</div>

					<Button secondary onClick={submit} className="">Done</Button>
				</div>
			</div>

		</section>


		<section>
			<h2 className="grades__sectionheader">Help Video</h2>
			<iframe className="embed" width="560" height="315" src="https://www.youtube.com/embed/LBnz7V89Z9A?si=Sa9nd8R-kKoGyUuy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</section>
		 <section>
			<h2 className="grades__sectionheader">About the Gradebook</h2>
			<p className="grades__setup__faq">
				<b>What is this for?</b> The gradebook tool helps you and your tutor calculate your grade in the class. It also facilitates communication between you and your tutor, so that your tutor can help you in the areas you struggle with most. This tool is intended to make you more likely to pass your class. 
			<p/>
				<b>Who will my grades be shared with?</b> Only passCS employees are allowed to know your grades when you enter them into this tool. Your tutor and their supervisor will routinely check these grades so we can best fit your experience to meet your needs
			<p/>
				<b>Is this required?</b> If you would like to qualify for our money back guarantee, yes. Using the gradebook feature, and making sure all grades are entered within a few days of you recieving in them, is required by our <Link target="_blank" to="/terms">terms</Link>. 
			<p/> 
				<b>Need help or have more questions?</b> It will be our pleasure to assist you or hear your feedback over the phone or via text message. Please call our support number: <i>(571) 572-9406</i>. 
				{/*
					Discuss the gradebook FAQs here 
					Helps you calculate your grade, since some professors don't have blackboard setup that way.
					Helps communicate progress in class to the tutor, and helps the tutor be more prepared to teach you the things you struggle with most in class
					Meant to improve your likelyhood of passing classes
					Define "dropped" assignment: if you don't know what it is put in zero

					About opting-out of the gradebook, and how they can opt out later too (though they cannot opt back in)
				*/}
			</p>
		</section>
		</>
	)
}

export function compute_overall_grade(grades, categories) {
	let grades_by_category = grades.reduce((category_groups, grade) => {
		return {...category_groups, [grade.grade_category]:category_groups[grade.grade_category] ? [...category_groups[grade.grade_category], grade] : [grade]}
	},{})

	let sums = Object.keys(grades_by_category).reduce((category_sums, category_id) => {
		return {...category_sums, [category_id]: 
			grades_by_category[category_id].sort((a,b) => {return a.points_recieved_hundreths/a.points_total_hundreths - b.points_recieved_hundreths/b.points_total_hundreths}).slice(Math.min(categories[category_id].drops,Math.max(grades_by_category[category_id].length-1, 0))).reduce(
				(sums,grade) => {
					sums.points_total_hundreths += grade.points_total_hundreths;
					sums.points_earned_hundreths += grade.points_recieved_hundreths;
					return sums;
				}, {points_earned_hundreths:0, points_total_hundreths:0} )}
	}, {})

	let numer = 0;
	let denom = 0; //canonically 100%. If there are grade categories with no grade entered yet (like a final exam), then this may be much lower than 100%.
	for(let category_id in sums) {
		denom += categories[category_id].weight
		numer += categories[category_id].weight * (sums[category_id].points_earned_hundreths/sums[category_id].points_total_hundreths)
	}
	
	return {
		overall: 100*numer/denom,
		by_category: sums
	}
}

export function GradebookMainView({gradebook, gradebook_id, categories, ...props}) {
	const [grades, setGrades] = useState(null) 

	useEffect(()=> {
		let load = async () => {
			// Load all grades for all categories
			
			let all_grades = [];
			for(let category in categories) {
				let gradesresp = await fetch(`/api/gradebooks/${gradebook_id}/categories/${category}/grades`);
				let gradesdata = await gradesresp.json();

				if(gradesdata.status==="success") {
					all_grades = all_grades.concat(gradesdata.data)
				} else {
					alert("Error loading data");
					return;
				}
			}
			setGrades(all_grades);
		}
		if(categories && Object.keys(categories).length > 0) {
			load()
		}
	},[gradebook_id, categories])

	let deleteGrade = async (grade_to_delete) => {
		let delreq  = await fetch(`/api/gradebooks/${grade_to_delete.gradebook}/categories/${grade_to_delete.grade_category}/grades/${grade_to_delete.id}`, {method: "DELETE"});
		let delresp = await delreq.json();

		if(delresp.status === "success") {
			// since the delete was succesful, delete the grade without doing a full update
			setGrades(grades.filter((grade) => grade.id !== grade_to_delete.id))
		} 
	}


	return (<>
		<section>
			<h2 className="dash__content__title">
				{!gradebook?.course?.course_number && <Loader text={5}/>}{gradebook?.course?.course_number && gradebook.course.course_number} Gradebook
			</h2>
			<GradeSummary grades={grades} categories={categories}/>
		</section>
		<section>
			<h2 className="grades__sectionheader">Add Grade</h2>
			<NewGradeForm grades={grades} setGrades={setGrades} gradebook_id={gradebook_id} categories={categories} />
		</section>
		<section>
			<h2 className="grades__sectionheader">Recently Entered Grades {grades===null && <Loader />}</h2>
			{grades && <Grades deleteGrade={deleteGrade} grades={grades} categories={categories}/>}
		</section>
		</>
	)
}

// Modal dialog shown to someone who has a completely empty gradebook, telling them about the gradebook and their options
export function SetupModal({gradebook, close, ...props}) {
	const {gradebooks, setGradebooks} = useOutletContext();
	const [page, setPage] = useState(0);
	const [optOutLoading, setOptOutLoading] = useState(false);
	const [error, setError] = useState(false);
	const navigate = useNavigate();

	let waive_guarantee = async () => {
		setOptOutLoading(true);
		setError(null)
		let waiveresp = await fetch(`/api/gradebooks/${gradebook.id}/archive`, {method:"POST"});
		let waivedata = await waiveresp.json();

		if(waivedata.status !== "success") {
			setError(({"DBError":"Internal Server Error", "Unauthorized":"Insufficient Permissions", "GradebookNotFound":"This gradebook does not exist. Please contact support or try agian later"})[waivedata.error] || waivedata.error)
			setOptOutLoading(false);
			return;
		}
		setPage(3);
		setOptOutLoading(false);
	}

	let pages = [(
		<Modal close={close} buttons={{primary:{text:"Next", onClick:() => setPage(1)}}} title={<>Get started with {gradebook && gradebook.course.course_number} tutoring</>} >
			We’re excited to meet you!<br/><br/>
passCS uses a gradebook calculator to help you calculate your overall grade, communicate your progress to your tutor, and stay on-track for class.
		</Modal>
	), (<Modal close={close} buttons={{secondaries:[{loading:optOutLoading, text:"Waive the passCS Guarantee", onClick:waive_guarantee}], primary:{text:"Keep the passCS Guarantee", onClick:() => setPage(2)}}} title={<>More about the gradebook tool</>} >
		In order to qualify for the passCS Guarantee you must use the gradebok to enter in grades for individual assignments within 7 days of them being posted or otherwise communicated to you. Additional <Link target="_blank" to="/terms">terms</Link> apply.
		<br/><br/>
			<i>If you choose to waive The passCS Guarantee, you cannot change your mind later</i>
		</Modal>),
	 (<Modal close={close} buttons={{primary:{text:"I'm Ready!", onClick:close}}} title={<>You’ll need your syllabus for this step</>} >
		 Pull up your syllabus and log-in to Blackboard, Gradescope, or wherever your course grades are kept.<br/><br/>
		 This step is designed to take fewer than 10 minutes of your time. 
		</Modal>),
		(<Modal close={() => {close(); navigate("/student/dashboard")}} buttons={{primary:{text:"View Scheduled Meetings", onClick:() => {close(); navigate("/student/dashboard")} }}} title={<>passCS Guarantee Waived</>} >
			You have succesfully waived the passCS Guarantee. We highly encourage you to communicate your expecatations for your tutoring experience to your tutor!
		</Modal>)
	]

	return pages[page];
}

export default function Gradebook({tutorview, ...props}) {
	const { page, setPage } = useOutletContext();
	const [categories, setCategories] = useState(null);
	const [showGradebook, setShowGradebook] = useState(true);
	const [gradebook, setGradebook] = useState(null);

	// If the user closed the setup modal and wants to go straight to gradebook setup
	const [forceSetup, setForceSetup] = useState(null);

	const {gradebook_id, customer_id}=useParams()

	useEffect(() => {
		setPage(`grades/${gradebook_id}`);
	}, [setPage, gradebook_id])
	
	// Load existing categories
	useEffect(() => {
		let load_categories = async () => {
			let categoriesresp = await fetch(`/api/gradebooks/${gradebook_id}/categories`);
			let categoriesdata = await categoriesresp.json();

			const existing_cats = categoriesdata.data.reduce((map, db_category) => {map[db_category.id] = {name: db_category.category_name, weight:db_category.weight_percent, drops: db_category.drops}; return map }, {});
			setCategories(existing_cats)
			setShowGradebook(!has_bad_category_sum(existing_cats))
		}

		let load_gradebook_customer = async () => {
			let gradebooksresp = await fetch(`/api/customers/myself/gradebooks`);
			let gradebooksdata = await gradebooksresp.json();

			let found_gradebook = gradebooksdata.data.filter((gb) => gb.id === gradebook_id)[0];
			setGradebook(found_gradebook);
		}

		let load_gradebook_tutor = async () => {
			// In this case we load myself since if this is a supervisor viewing another tutor's dashboard
			let gradebooksresp = await fetch(`/api/tutors/myself/gradebooks?show_all=true`);
			let gradebooksdata = await gradebooksresp.json();

			let found_gradebook = gradebooksdata.data.filter((gb) => gb.id === gradebook_id)[0];
			setGradebook(found_gradebook);
		}
		load_categories();
		if(tutorview) {
			load_gradebook_tutor();
		} else {
			load_gradebook_customer();
		}
	}, [tutorview, gradebook_id])

	

	if(!showGradebook) {
		if(!tutorview && !(gradebook && gradebook.archived) && categories != null && Object.keys(categories).length === 0 && !forceSetup) {
			return (
				<>
					<SetupModal close={() => setForceSetup(true)} gradebook={gradebook}/>
				</>
			)
		} else {
			return (
				<div className="booking_container">
					<CategorySetupView gradebook={gradebook} gradebook_id={gradebook_id} categories={categories} setCategories={setCategories} onComplete={() => setShowGradebook(true)}  tutorview={tutorview} />
				</div>)
		}
	} else {
		return (
			<div className="booking_container">
				<GradebookMainView gradebook={gradebook} gradebook_id={gradebook_id} categories={categories}/>
			</div>
		)
	}
}
